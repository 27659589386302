import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import { ContextApp } from "../utils/Context";
import { API } from "./MyApi";
import { logout } from "@carrier-sce-sharedpackages/auth-module";
import appConfig from "../config";
import logo from "../pics/logo.png";
import databotlogo from "../pics/Databotlogo.png";

export default function Header() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [user, setUser] = React.useState("User");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    get_chiller_list();
  }, []);

  const get_chiller_list = async () => {
    const param = {};
    try {
      const userdata = await API.getData("/get_username", param);
      console.log("userdata", userdata);

      if (userdata && userdata.username) {
        setUser(userdata.username);
      }
    } catch (error) {
      console.error("Failed to userdata", error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    logout(appConfig.api, true);
  };

  return (
    <React.Fragment>
      <AppBar color="primary" position="fixed" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs>
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: "130px",
                  height: "50px",
                  position: "relative",
                  left: "30px",
                  top: "0px",
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography
                color="inherit"
                variant="h5"
                component="h1"
                sx={{
                  fontFamily: "monospace !important",
                  flex: 1,
                }}
              >
                AI/ML CoE - AInsight Bot
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  fontSize: "0.8rem",
                  marginRight: theme.spacing(2),
                  bgcolor: "#ce8600",
                }}
              >
                {user[0]}
              </Avatar>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: "0.8rem !important",
                  color: "common.white",
                  fontWeight: "bold !important",
                  marginRight: theme.spacing(1),
                }}
              >
                {user}
              </Typography>
            </Grid>

            <Grid item>
              <Tooltip title="Logout">
                <IconButton
                  sx={{
                    color: "common.white",
                    marginRight: theme.spacing(1),
                  }}
                  onClick={handleLogout} // Define your logout function
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
