import Home from "./Page/Home";
import ResponsiveAppBar from "./components/Navbar";
import "./App.css";
import { useState } from "react";
import { ReactAuthWrapper } from "@carrier-sce-sharedpackages/reactauthwrapper";
import appConfig from "./config";

function App() {
  const [appAccess, setAppAccess] = useState(false);
  const callback = (permissions) => {
    console.log("permissions->", permissions);
    if (permissions.appAccess) {
      console.log("permissions->", permissions.appAccess);
      setAppAccess(true);
    }
  };

  return (
    <ReactAuthWrapper appConfig={appConfig.api} getPermissions={callback}>
      <div className="overflow-hidden">
        {appAccess && (
          <>
            <ResponsiveAppBar />
            <Home />
          </>
        )}
      </div>
    </ReactAuthWrapper>
  );
}

export default App;
